<script setup lang="ts">
import { defineProps, defineEmits, onBeforeMount, ref, watch } from "vue";
import type { InstallationTermin } from "@/types";
import TerminLoader from "../Loaders/TerminLoader.vue";
import ImageLoader from "../Loaders/ImageLoader.vue";
import client from "@/utils/axios";
import { formatStartEndDate } from "@/utils/format-date";
import { installationTerminStageList } from "@/utils/constants";
import InlineLoader from "../Loaders/InlineLoader.vue";

type Props = {
  installationTermin: InstallationTermin;
  activeTerminId: string;
};

const props = defineProps<Props>();

const emit = defineEmits(["on-close"]);

const closeTerminModal = () => {
  emit("on-close");
};

const activeTermin = ref();

const projectTerminLoading = ref(true);

const getProjectTerminData = async (id: string) => {
  const response = await client.get(`/projects/notes/${id}`);

  activeTermin.value = response.data;

  projectTerminLoading.value = false;
};

onBeforeMount(() => {
  getProjectTerminData(props.activeTerminId);
});

const removeLoaderOverlay = (index: string) => {
  const loader = document.getElementById(`loader-${index}`);
  if (loader) {
    loader.remove();
  }
};

watch(
  () => props.activeTerminId,
  (newTerminId: string) => {
    projectTerminLoading.value = true;
    getProjectTerminData(newTerminId);
  }
);
</script>

<template>
  <div class="flex flex-col lg:h-[83vh] overflow-hidden">
    <!-- Top side -->
    <div class="min-h-24">
      <div class="flex justify-between mb-4">
        <div class="flex items-center gap-2">
          <div
            v-if="projectTerminLoading"
            class="flex flex-col justify-center mt-5"
          >
            <InlineLoader />
          </div>
          <h4 v-else class="text-base font-semibold text-[#393840]">
            {{ activeTermin?.name }}
          </h4>
          <div
            v-if="projectTerminLoading"
            class="flex flex-col justify-center mt-5"
          >
            <InlineLoader />
          </div>
          <span
            v-else
            class="text-sm font-normal text-[#616068] rounded-md py-[2px] px-2"
            :style="{
              backgroundColor: installationTerminStageList.find(
                (status) => status.name === activeTermin?.stage
              )?.color,
            }"
          >
            {{ activeTermin?.stage }}
          </span>
        </div>
        <div class="cursor-pointer" @click="closeTerminModal">
          <img src="@/assets/close-icon.svg" alt="Close" />
        </div>
      </div>
      <!-- employees -->
      <div>
        <div class="flex items-center gap-2">
          <img src="@/assets/full-calendar-icon.svg" alt="Calendar icon" />
          <div v-if="projectTerminLoading" class="flex flex-col justify-center">
            <InlineLoader />
          </div>
          <div v-else class="flex items-center gap-1">
            {{ formatStartEndDate(activeTermin?.start, activeTermin?.end) }}
          </div>
        </div>
        <div class="flex items-center gap-2">
          <img src="@/assets/employees-icon.svg" alt="Calendar icon" />
          <div v-if="projectTerminLoading" class="flex flex-col justify-center">
            <InlineLoader />
          </div>
          <p v-else>{{ activeTermin?.teams }}</p>
        </div>
      </div>
    </div>
    <div v-if="projectTerminLoading" class="flex flex-col justify-center mt-5">
      <div v-for="index in 3" :key="index">
        <TerminLoader />
      </div>
    </div>
    <div
      v-else-if="Object.keys(activeTermin?.images).length"
      class="lg:min-h-[calc(83vh-96px)] overflow-auto"
    >
      <!-- Section description -->
      <div
        v-for="(terminDetail, ptName, ptIndex) in activeTermin.images"
        :key="ptIndex"
        class="mt-5"
      >
        <h3 class="pb-2 text-base font-semibold text-[#393840]">
          {{ ptName }}
        </h3>
        <div class="w-full flex items-center gap-4 overflow-auto">
          <div
            v-for="(terminImage, imgIndex) in terminDetail"
            :key="imgIndex"
            class="flex items-center gap-4"
          >
            <div class="w-[350px] h-[350px] relative">
              <div
                :id="`loader-${ptIndex}-${imgIndex}`"
                class="absolute w-[350px] h-[350px] bg-[#E3E1EA]"
              >
                <ImageLoader />
              </div>
              <img
                class="max-w-[350px] w-[350px] h-[350px] object-cover rounded-lg"
                :src="terminImage"
                :alt="terminDetail.title"
                @load="removeLoaderOverlay(`${ptIndex}-${imgIndex}`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m-auto">
      <img
        src="@/assets/no-results/installation-steps.png"
        alt="No Installation Documents"
      />
    </div>
  </div>
</template>
