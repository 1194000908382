<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { onBeforeMount, ref } from "vue";
import type { ProjectDownload } from "@/types";
import {
  getDownloadStatusColor,
  getDownloadStatusName,
} from "@/utils/status-color";
import DownloadLoader from "@/components/Loaders/DownloadLoader.vue";
import { toast } from "vue3-toastify";

const downloadData = ref<ProjectDownload[]>();

const formattedDate = (date: string) => {
  return new Date(date).toLocaleDateString("de");
};

const loading = ref(false);

const getDownloadData = async () => {
  loading.value = true;
  const response = await client.get("/projects/download-documents");

  if (response.status >= 400) {
    toast.error(response.statusText);
  }
  downloadData.value = response.data;
  loading.value = false;
};

onBeforeMount(() => {
  getDownloadData();
});

const downloadHandler = async (id: string) => {
  const response = await client.put(`/projects/mark-as-downloaded/${id}`);
  if (response.status === 200) {
    getDownloadData();
  }
};
</script>

<template>
  <MainLayout>
    <!-- Project downloads -->
    <div class="p-4 sm:p-6">
      <div
        class="bg-white rounded-xl px-[12px] py-[14px] w-full lg:w-[70%] 2xl:w-[50%]"
      >
        <div class="shadow-md rounded-lg">
          <div>
            <h5
              class="bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]"
            >
              My downloads
            </h5>
          </div>
          <div v-if="loading" class="flex flex-col items-center justify-center">
            <div
              v-for="index in 3"
              :key="index"
              class="flex items-center justify-between px-3 py-3 w-full"
              :class="index % 2 !== 0 ? 'bg-[#F1F0F5]' : 'bg-white'"
            >
              <DownloadLoader />
            </div>
          </div>
          <div v-else-if="downloadData?.length">
            <div
              v-for="(download, index) in downloadData"
              :key="download.id"
              class="flex items-center justify-between px-3 py-3"
              :class="index % 2 !== 0 ? 'bg-[#F1F0F5]' : 'bg-white'"
            >
              <div
                class="flex gap-2 flex-col sm:flex-row items-start sm:items-center"
              >
                <div class="text-sm sm:text-base font-semibold text-[#616068]">
                  {{ download.name.split("_")[0] }}
                </div>
                <span class="text-xs sm:text-sm">{{
                  formattedDate(download.date)
                }}</span>
                <div
                  :class="`text-xs sm:text-sm text-center font-normal text-[#616068] capitalize rounded-md py-[2px] px-2`"
                  :style="{
                    backgroundColor: `${getDownloadStatusColor(
                      download.status
                    )}`,
                  }"
                >
                  {{ getDownloadStatusName(download.status) }}
                </div>
              </div>
              <!-- Download button -->
              <a
                v-if="download.status !== 'in-progress'"
                :href="download.url"
                target="_blank"
                class="w-5 h-5 ml-1 sm:w-7 sm:h-7 flex items-center justify-center rounded-lg hover:bg-[#E3E1EA]"
                @click="downloadHandler(download.id)"
              >
                <img src="@/assets/download-icon.svg" alt="Download icon" />
              </a>
            </div>
          </div>
          <div v-else>
            <img
              src="@/assets/no-results/downloads.png"
              alt="No downloads"
              class="mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
