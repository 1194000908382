export interface Project {
  id: string;
  name: string;
  types: Array<string>;
  installationDates: {
    start: string;
    end: string;
    time_zone?: string;
  };
  totalSteps?: string;
  finishedSteps?: string;
  status: Status;
  stage?: any;
  installationTermins?: Array<any>;
}

export interface Customer {
  name: string;
  logo_url: string | null;
  notion_id: string;
}
export interface User {
  fullName: string;
  email: string;
  lastLogin: string;
  customers: Array<Customer>;
  accessToken: string;
  refreshToken: string;
}

export interface ProjectDocument {
  id: string;
  url: string;
  name: string;
}
export interface InstallationTermin {
  id: string;
  name: string;
  stage: any;
  date: any;
  start: string;
  end: string;
  parentProjectId: string;
  installationTeams: string;
  installationSteps: Array<string>;
}

export interface Status {
  id: string;
  color: string;
  name: string;
}

export interface InstallationTerminStage {
  name: string;
  color: string;
}

export type Termin = {
  name: string;
  status: string;
  date: Date[];
  employee: Array<string>;
};

// export interface ProjectDetail {
//   id: string;
//   name: string;
//   types: Array<string>;
//   installationDates: string;
//   totalSteps: string;
//   finishedSteps: string;
//   projectStatus: Status;
//   termin: Termin;
// }

export enum DateFilters {
  ALL = "all",
  TODAY = "today",
  THIS_WEEK = "this_week",
  CUSTOM_DATES = "custom_dates",
}

export type ProjectStore = {
  projects: Array<Project>;
  hasMore: boolean | undefined;
  isLoading: boolean;
  lastRefreshed: Date | undefined;
  nextCursor: string | undefined;
  filterCursors: Array<{
    id: string;
    nextCursor: string | undefined;
    hasMore: boolean | undefined;
  }>;
};

export interface Document {
  url: string;
  name: string;
}

interface InstallationDates {
  start: string;
  end: string;
  time_zone?: string;
}

interface InstallationTermins {
  date: InstallationDates;
  id: string;
  name: string;
  installationTeams: string;
  installationSteps: Array<string>;
  status: Status;
  parentProjectId: string;
}
export interface ProjectDetail {
  id: string;
  stage: any;
  name: string;
  totalSteps: number;
  finishedSteps: number;
  installationTermins?: Array<InstallationTermin>;
  address?: string;
  homeowner_name?: string;
  homeowner_phone?: string;
  documents?: Array<ProjectDocument>;
}

export interface ProjectComment {
  notionId: string;
  source: string;
  postedBy: string;
  text: string;
  createdAt: string;
  isAdmin: boolean;
}

export interface SingleProjectStore {
  id: string;
  project: ProjectDetail;
}

export interface ProjectDownload {
  id: string;
  name: string;
  status: string;
  url: string;
  date: string;
}
