import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/card-icon.svg'


const _hoisted_1 = { class: "flex flex-col p-[8px] bg-white rounded-lg border border-[#F1F0F5] min-h-32 justify-between drop-shadow" }
const _hoisted_2 = { class: "flex items-center justify-between mb-2" }
const _hoisted_3 = { class: "text-sm text-[#393840] font-medium" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }


import { formatStartEndDate } from "@/utils/format-date";

type Props = {
  projectId: string;
  projectName: string;
  projectStatusName: string;
  projectStatusColor: string;
  projectInstallationDates: Array<any>;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectCard',
  props: {
    projectId: {},
    projectName: {},
    projectStatusName: {},
    projectStatusColor: {},
    projectInstallationDates: {}
  },
  setup(__props: any) {

const props = __props;

let startDate = "9999-12-31T23:59:59+00:00";
let endDate = "1970-01-01T00:00:00+00:00";

let installationDates = "";

if (props.projectInstallationDates?.length) {
  props.projectInstallationDates.forEach((installationDate) => {
    if (!installationDate.start || !installationDate.end) {
      return;
    }
    if (new Date(installationDate.start) < new Date(startDate)) {
      startDate = installationDate.start;
    }

    if (new Date(installationDate.end) > new Date(endDate)) {
      endDate = installationDate.end;
    }
  });
  // we are not on default dates
  if (
    startDate !== "9999-12-31T23:59:59+00:00" &&
    endDate !== "1970-01-01T00:00:00+00:00"
  ) {
    installationDates = formatStartEndDate(startDate, endDate);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(`px-2 py-[2px] rounded-lg text-[10px] text-[#393840] font-normal`),
          style: _normalizeStyle({ backgroundColor: `${props.projectStatusColor}` })
        }, _toDisplayString(props.projectStatusName), 5),
        _createVNode(_component_RouterLink, {
          class: "cursor-pointer rounded-lg hover:bg-[#E3E1EA] p-1",
          to: { name: 'ProjectDetails', params: { id: props.projectId } }
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Icon"
              })
            ], -1)
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(props.projectName), 1)
    ]),
    (_unref(installationDates).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "text-xs text-[#A7A5AE] font-normal" }, "Installation dates:", -1)),
          _createElementVNode("h5", {
            class: "text-[#393840] text-xs font-normal",
            innerHTML: _unref(installationDates)
          }, null, 8, _hoisted_5)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
          _createElementVNode("h4", { class: "text-xs text-[#A7A5AE] font-normal" }, "No installation dates", -1)
        ])))
  ]))
}
}

})