import { defineStore } from "pinia";
import type { User } from "@/types";
import { useProjectStore } from "./projects";
import { useSingleProjectStore } from "./singleProject";

export const useUserStore = defineStore("user", {
  state: (): User => ({
    fullName: "",
    email: "",
    lastLogin: "",
    customers: [],
    accessToken: "",
    refreshToken: "",
  }),

  getters: {
    getUser: (state) => {
      // console.log(state.accessToken);
      return {
        fullName: state.fullName,
        email: state.email,
        lastLogin: state.lastLogin,
      };
    },
    getAccessToken: (state) => state.accessToken,
    getCustomerName: (state) => {
      const custWithLogo = state.customers.filter((cust) => cust.logo_url);

      if (custWithLogo.length > 0) {
        return custWithLogo[0].name;
      }

      return state.customers[0].name;
    },
    getCustomerNames: (state) => state.customers.map((cust) => cust.name),
    getCustomerLogo: (state) => {
      const custWithLogo = state.customers.filter((cust) => cust.logo_url);
      if (custWithLogo.length > 0) {
        return custWithLogo[0].logo_url;
      }

      return "";
    },
  },

  actions: {
    async checkAuth() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_ENDPOINT}/api/auth/check-session`,
          {
            headers: {
              "x-supabase-auth": `${this.accessToken}`,
            },
          }
        );

        if (response.status !== 200) {
          this.logout();
          return;
        }
      } catch (e) {
        this.logout();
      }
    },

    async login(email: string, password: string) {
      const response = await fetch(
        `${process.env.VUE_APP_API_ENDPOINT}/api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      this.fullName = data.fullName;
      this.email = data.email;
      this.lastLogin = data.lastLogin;
      this.accessToken = data.accessToken;
      this.refreshToken = data.refreshToken;
      this.customers = data.customers;
    },
    logout() {
      this.fullName = "";
      this.email = "";
      this.lastLogin = "";
      this.accessToken = "";
      this.refreshToken = "";
      this.customers = [];
      useProjectStore().clearProjects();
      useSingleProjectStore().clearSingleProject();
    },
  },
  persist: true,
});
