import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/all-icon.svg'
import _imports_1 from '@/assets/sun-icon.svg'
import _imports_2 from '@/assets/blocks-icon.svg'
import _imports_3 from '@/assets/search-icon.svg'
import _imports_4 from '@/assets/refresh-icon.svg'
import _imports_5 from '@/assets/filter-icon.svg'
import _imports_6 from '@/assets/chevron-down.svg'
import _imports_7 from '@/assets/chevron-up.svg'
import _imports_8 from '@/assets/caret.svg'
import _imports_9 from '@/assets/no-results/dashboard.svg'


const _hoisted_1 = { class: "relative w-full p-4 sm:pl-5 sm:pr-4 sm:py-6" }
const _hoisted_2 = { class: "mb-12 flex flex-col lg:flex-row justify-between items-center max-w-[1360px] mx-auto" }
const _hoisted_3 = { class: "flex flex-col gap-4 lg:flex-row flex-wrap mr-0 lg:mr-7" }
const _hoisted_4 = { class: "flex items-center gap-2 flex-wrap sm:flex-nowrap justify-center lg:justify-start" }
const _hoisted_5 = { class: "w-full sm:w-[390px] flex items-center justify-between bg-white rounded-lg py-[6px] px-2 2xl:ml-5" }
const _hoisted_6 = { class: "w-[80%] flex items-center gap-2" }
const _hoisted_7 = { class: "mt-3 md:mt-0 flex items-center gap-4" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "w-[160px]" }
const _hoisted_10 = { class: "relative w-full bg-transparent" }
const _hoisted_11 = { class: "flex gap-1" }
const _hoisted_12 = { class: "select-selected" }
const _hoisted_13 = {
  key: 0,
  class: "select-items"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 1,
  class: "w-[9px] h-[5px] mt-2 cursor-pointer",
  src: _imports_6,
  alt: ""
}
const _hoisted_16 = {
  key: 2,
  class: "w-[9px] h-[5px] mt-2",
  src: _imports_7,
  alt: ""
}
const _hoisted_17 = {
  key: 0,
  class: "flex flex-col gap-[6px] w-full overflow-x-auto sm:flex-row max-w-[1360px] mx-auto"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "text-xs text-[#393840] font-medium" }
const _hoisted_20 = {
  key: 0,
  class: "flex gap-[6px] overflow-auto sm:flex-col mt-3"
}
const _hoisted_21 = {
  key: 1,
  class: "flex gap-[6px] overflow-auto sm:flex-col mt-3"
}
const _hoisted_22 = {
  key: 1,
  class: "flex justify-center mt-36"
}

import "/custom.css";
import MainLayout from "@/layouts/MainLayout.vue";
import type { Project, Status } from "@/types";
import { DateFilters } from "@/types";
import { statusList } from "@/utils/constants";
import { useProjectStore } from "@/stores/projects";
import ProjectCard from "@/components/ProjectCard.vue";
import { ref, computed } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const projectList = ref<Array<Project>>([]);
const projectsStore = useProjectStore();
const pageNumber = ref(1);

projectsStore.getProjects(pageNumber.value).then((projects: Project[]) => {
  projectList.value = projects;
});
const searchInput = ref<string>("");

const currentFilter = ref<DateFilters>(DateFilters.ALL);
const isSelectedMenu = ref(false);
const selecteStatusFilter = ref<string>("");

const isSelectMenuHandler = () => {
  isSelectedMenu.value = !isSelectedMenu.value;
};

const statusFilter = (status: string) => {
  selecteStatusFilter.value = status;
  isSelectedMenu.value = false;
};

const closedColumns = ref<Array<string>>([]);

const filteredProjects = computed(() => {
  let projects: Project[] = projectList.value;
  // filter by date filter
  switch (currentFilter.value) {
    case DateFilters.ALL:
      break;
    case DateFilters.TODAY:
      projects = projects?.filter((project) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.installationTermins?.forEach((termin) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const projectStartToday = start
            ? start >= currentDate && start <= currentDate
            : false;

          const projectEndToday = end
            ? end >= currentDate && end <= currentDate
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartToday || projectEndToday || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
    case DateFilters.THIS_WEEK:
      projects = projects.filter((project) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.installationTermins?.forEach((termin) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          const monday = new Date(currentDate.setDate(diff));
          const sunday = new Date(currentDate.setDate(diff + 6));

          const projectStartThisWeek = start
            ? start >= monday && start <= sunday
            : false;

          const projectEndThisWeek = end
            ? end >= monday && end <= sunday
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartThisWeek || projectEndThisWeek || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
  }

  if (searchInput.value.length) {
    projects = projects?.filter((project) => {
      return project.name
        .toLowerCase()
        .includes(searchInput.value.toLowerCase());
    });
  }

  return projects;
});

const filteredColumns = computed(() => {
  if (!selecteStatusFilter.value) {
    return statusList;
  }
  return statusList?.filter(
    (status: Status) => status.name === selecteStatusFilter.value
  );
});

const refreshProjects = () => {
  if (projectsStore.getIsLoading) {
    return;
  }

  projectsStore.refreshProjects().then((projects: Project[]) => {
    projectList.value = projects;
  });
};

const closeColumnToggle = (id: string) => {
  if (closedColumns.value.includes(id)) {
    closedColumns.value = closedColumns.value?.filter((item) => item !== id);
  } else {
    closedColumns.value = [...closedColumns.value, id];
  }
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass({
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter.value === _unref(DateFilters).ALL,
              }),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (currentFilter.value = _unref(DateFilters).ALL))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Icon"
                }, null, -1),
                _createElementVNode("h5", { class: "text-sm text-[#393840]" }, "All", -1)
              ]), 2),
              _createElementVNode("div", {
                class: _normalizeClass({
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter.value === _unref(DateFilters).TODAY,
              }),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (currentFilter.value = _unref(DateFilters).TODAY))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "Icon"
                }, null, -1),
                _createElementVNode("h5", { class: "text-sm text-[#393840]" }, "Today", -1)
              ]), 2),
              _createElementVNode("div", {
                class: _normalizeClass({
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter.value === _unref(DateFilters).THIS_WEEK,
              }),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (currentFilter.value = _unref(DateFilters).THIS_WEEK))
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("img", {
                  src: _imports_2,
                  alt: "Icon"
                }, null, -1),
                _createElementVNode("h5", { class: "text-sm text-[#393840]" }, "This week", -1)
              ]), 2)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[10] || (_cache[10] = _createElementVNode("img", {
                  src: _imports_3,
                  alt: "Search"
                }, null, -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchInput).value = $event)),
                  class: "w-full bg-transparent focus:outline-none pr-2 text-[#393840] text-xs sm:text-sm",
                  type: "text",
                  placeholder: "Search by project name"
                }, null, 512), [
                  [_vModelText, searchInput.value]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "flex items-center gap-2 font-normal text-sm text-[#393840] hover:bg-white rounded-lg px-3 py-3",
              disabled: _unref(projectsStore).getIsLoading,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (refreshProjects()))
            }, [
              _createElementVNode("img", {
                class: _normalizeClass({ 'animate-spin': _unref(projectsStore).getIsLoading }),
                src: _imports_4,
                alt: "loading-icon"
              }, null, 2),
              _cache[11] || (_cache[11] = _createTextVNode(" Refresh "))
            ], 8, _hoisted_8),
            _createElementVNode("form", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                    src: _imports_5,
                    alt: "Icon"
                  }, null, -1)),
                  _createElementVNode("div", {
                    class: "custom-select",
                    onClick: isSelectMenuHandler,
                    onFocusout: _cache[6] || (_cache[6] = ($event: any) => (isSelectedMenu.value = false))
                  }, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(selecteStatusFilter.value
                        ? selecteStatusFilter.value
                        : "Filter Status"), 1),
                    (isSelectedMenu.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", {
                              class: "select-item",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (statusFilter('')))
                            }, " All ")
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (stage) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: stage.id
                            }, [
                              _createElementVNode("div", {
                                class: "select-item",
                                onClick: ($event: any) => (statusFilter(stage.name))
                              }, _toDisplayString(stage.name), 9, _hoisted_14)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (!isSelectedMenu.value)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                      : (_openBlock(), _createElementBlock("img", _hoisted_16))
                  ], 32)
                ])
              ])
            ])
          ])
        ]),
        (filteredProjects.value.length || _unref(projectsStore).getIsLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredColumns.value, (stage) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: stage.id,
                  class: _normalizeClass({
            'flex flex-col bg-white p-[6px] rounded-md h-max min-w-[160px] w-[160px]': true,
            'gap-3':
              !closedColumns.value.includes(stage.id) && filteredProjects.value?.filter(
                  (item) => item.stage?.id === stage.id
                ).length > 1,
          })
                }, [
                  _createElementVNode("div", {
                    class: "flex items-center justify-between cursor-pointer select-none",
                    onClick: ($event: any) => (closeColumnToggle(stage.id))
                  }, [
                    (!_unref(projectsStore).getIsLoading)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`p-1 w-6 h-6 flex items-center justify-center rounded-lg text-xs text-[#393840] font-semibold`),
                          style: _normalizeStyle({ backgroundColor: `${stage.color}` })
                        }, _toDisplayString(filteredProjects.value?.filter(
                  (item) => item.stage?.id === stage.id
                ).length), 5))
                      : _createCommentVNode("", true),
                    _createElementVNode("h5", _hoisted_19, _toDisplayString(stage.name), 1),
                    _createElementVNode("img", {
                      src: _imports_8,
                      alt: "Icon",
                      class: _normalizeClass({
                'cursor-pointer': true,
                'transform rotate-180': closedColumns.value.includes(stage.id),
              })
                    }, null, 2)
                  ], 8, _hoisted_18),
                  (_unref(projectsStore).getIsLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.floor(Math.random() * 5) + 1, (index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "flex flex-col p-[10px] bg-white rounded-lg border border-[#A7A5AE] min-h-32"
                          }, [
                            _createVNode(CardLoader)
                          ]))
                        }), 128))
                      ]))
                    : (!closedColumns.value.includes(stage.id) && filteredProjects.value?.filter((item)=> item.stage?.id === stage.id).length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredProjects.value.filter((item)=> item.stage?.id === stage.id), (listItem) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: listItem.id,
                              class: "w-full"
                            }, [
                              _createVNode(_component_RouterLink, {
                                to: { name: 'ProjectDetails', params: { id: listItem.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(ProjectCard, {
                                    "project-id": listItem.id,
                                    "project-name": listItem.name,
                                    "project-status-color": 
                  _unref(statusList).find(
                    (statusItem) => statusItem.id === listItem.stage.id
                  )?.color || '#A8C5DA'
                ,
                                    "project-status-name": listItem.stage.name,
                                    "project-installation-dates": 
                    listItem.installationTermins || []
                  
                                  }, null, 8, ["project-id", "project-name", "project-status-color", "project-status-name", "project-installation-dates"])
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[13] || (_cache[13] = [
              _createElementVNode("img", {
                src: _imports_9,
                alt: "No results"
              }, null, -1)
            ])))
      ])
    ]),
    _: 1
  }))
}
}

})