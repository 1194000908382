import { defineStore } from "pinia";
import type { ProjectStore, Project } from "@/types";
import { DateFilters } from "@/types";
import client from "@/utils/axios";
import { toast } from "vue3-toastify";

export const useProjectStore = defineStore("project", {
  state: (): ProjectStore => ({
    projects: [],
    hasMore: true,
    isLoading: false,
    lastRefreshed: undefined,
    nextCursor: undefined,
    filterCursors: [],
  }),

  getters: {
    getIsLoading: (state) => state.isLoading,
  },

  actions: {
    /**
     * Reset projects, nextCursor and hasMore flags
     * @returns void
     */
    resetProjects() {
      this.projects = [];
      this.nextCursor = undefined;
      this.hasMore = undefined;
      this.filterCursors = [];
    },
    /**
     * Refresh projects from the API. Guarded by isRefreshing and isLoading flags.
     * If last refresh happened less than 5 minutes ago, don't refresh again.
     * @returns projects
     */
    async refreshProjects() {
      if (this.isLoading) {
        return this.projects;
      }

      if (this.lastRefreshed) {
        const now = new Date();
        const lastRefreshed = new Date(this.lastRefreshed);
        const diff = now.getTime() - lastRefreshed.getTime();
        // if refresh happened less than 5 minutes ago, don't refresh again
        // if (diff < 5 * 60 * 1000) {
        //   toast.error(
        //     "Refreshed less than 5 minutes ago. Please try again later."
        //   );
        //   return this.projects;
        // }
      }

      this.isLoading = true;
      this.resetProjects();

      await this.fetchProjects();

      this.isLoading = false;

      this.lastRefreshed = new Date();

      return this.projects;
    },
    /**
     * Get projects, paginated
     * @param page page that we want to get projects from
     * @returns projects from the page
     */
    async getProjects(page = 1) {
      // if we load first time and we have projects, return them
      if (this.projects.length && page === 1) {
        return this.projects;
      }
      // if not, fetch it
      this.isLoading = true;
      await this.fetchProjects();
      this.isLoading = false;

      return this.projects;
    },
    /**
     * Fetch projects from the API
     * Should be called only from loadMoreProjects and refreshProjects
     *
     * @returns void
     */
    async fetchProjects() {
      try {
        const queryParameters = new URLSearchParams();
        if (this.nextCursor) {
          queryParameters.append("cursor", this.nextCursor);
        }
        this.isLoading = true;
        const response = await client.get(
          `/projects?${queryParameters.toString()}`
        );

        const receivedProjectList = response.data || [];

        receivedProjectList.forEach((project: Project) => {
          // check do we already have that ID
          const existingProjectIndex = this.projects.findIndex(
            (p) => p.id === project.id
          );

          // if we do, skip
          if (existingProjectIndex > -1) {
            return;
          }

          this.projects.push(project);
        });

        this.isLoading = false;
        this.nextCursor = response.data?.nextCursor;
        this.hasMore = response.data?.hasMore;
      } catch (error) {
        console.error("Failed to fetch projects:", error);
        toast.error("Failed to fetch projects");
      }
    },
    /**
     * Clear project data
     */
    clearProjects() {
      this.projects = [];
      this.hasMore = true;
      this.isLoading = false;
      this.lastRefreshed = undefined;
      this.nextCursor = undefined;
      this.filterCursors = [];
    },
  },
  persist: true,
});
