<script lang="ts" setup>
import "/custom.css";
import MainLayout from "@/layouts/MainLayout.vue";
import type { Project, Status } from "@/types";
import { DateFilters } from "@/types";
import { statusList } from "@/utils/constants";
import { useProjectStore } from "@/stores/projects";
import ProjectCard from "@/components/ProjectCard.vue";
import { ref, computed } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";

const projectList = ref<Array<Project>>([]);
const projectsStore = useProjectStore();
const pageNumber = ref(1);

projectsStore.getProjects(pageNumber.value).then((projects: Project[]) => {
  projectList.value = projects;
});
const searchInput = ref<string>("");

const currentFilter = ref<DateFilters>(DateFilters.ALL);
const isSelectedMenu = ref(false);
const selecteStatusFilter = ref<string>("");

const isSelectMenuHandler = () => {
  isSelectedMenu.value = !isSelectedMenu.value;
};

const statusFilter = (status: string) => {
  selecteStatusFilter.value = status;
  isSelectedMenu.value = false;
};

const closedColumns = ref<Array<string>>([]);

const filteredProjects = computed(() => {
  let projects: Project[] = projectList.value;
  // filter by date filter
  switch (currentFilter.value) {
    case DateFilters.ALL:
      break;
    case DateFilters.TODAY:
      projects = projects?.filter((project) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.installationTermins?.forEach((termin) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const projectStartToday = start
            ? start >= currentDate && start <= currentDate
            : false;

          const projectEndToday = end
            ? end >= currentDate && end <= currentDate
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartToday || projectEndToday || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
    case DateFilters.THIS_WEEK:
      projects = projects.filter((project) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.installationTermins?.forEach((termin) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          const monday = new Date(currentDate.setDate(diff));
          const sunday = new Date(currentDate.setDate(diff + 6));

          const projectStartThisWeek = start
            ? start >= monday && start <= sunday
            : false;

          const projectEndThisWeek = end
            ? end >= monday && end <= sunday
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartThisWeek || projectEndThisWeek || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
  }

  if (searchInput.value.length) {
    projects = projects?.filter((project) => {
      return project.name
        .toLowerCase()
        .includes(searchInput.value.toLowerCase());
    });
  }

  return projects;
});

const filteredColumns = computed(() => {
  if (!selecteStatusFilter.value) {
    return statusList;
  }
  return statusList?.filter(
    (status: Status) => status.name === selecteStatusFilter.value
  );
});

const refreshProjects = () => {
  if (projectsStore.getIsLoading) {
    return;
  }

  projectsStore.refreshProjects().then((projects: Project[]) => {
    projectList.value = projects;
  });
};

const closeColumnToggle = (id: string) => {
  if (closedColumns.value.includes(id)) {
    closedColumns.value = closedColumns.value?.filter((item) => item !== id);
  } else {
    closedColumns.value = [...closedColumns.value, id];
  }
};
</script>
<template>
  <MainLayout>
    <div class="relative w-full p-4 sm:pl-5 sm:pr-4 sm:py-6">
      <!-- Top side -->
      <div
        class="mb-12 flex flex-col lg:flex-row justify-between items-center max-w-[1360px] mx-auto"
      >
        <!-- Left side -->
        <div class="flex flex-col gap-4 lg:flex-row flex-wrap mr-0 lg:mr-7">
          <div
            class="flex items-center gap-2 flex-wrap sm:flex-nowrap justify-center lg:justify-start"
          >
            <!-- Item -->
            <div
              :class="{
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter === DateFilters.ALL,
              }"
              @click="currentFilter = DateFilters.ALL"
            >
              <img src="@/assets/all-icon.svg" alt="Icon" />
              <h5 class="text-sm text-[#393840]">All</h5>
            </div>
            <!-- Item -->
            <div
              :class="{
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter === DateFilters.TODAY,
              }"
              @click="currentFilter = DateFilters.TODAY"
            >
              <img src="@/assets/sun-icon.svg" alt="Icon" />
              <h5 class="text-sm text-[#393840]">Today</h5>
            </div>
            <!-- Item -->
            <div
              :class="{
                'flex items-center px-3 py-3 gap-2 cursor-pointer hover:bg-white rounded-lg duration-300': true,
                'bg-white': currentFilter === DateFilters.THIS_WEEK,
              }"
              @click="currentFilter = DateFilters.THIS_WEEK"
            >
              <img src="@/assets/blocks-icon.svg" alt="Icon" />
              <h5 class="text-sm text-[#393840]">This week</h5>
            </div>
          </div>
          <!-- Search bar -->
          <div
            class="w-full sm:w-[390px] flex items-center justify-between bg-white rounded-lg py-[6px] px-2 2xl:ml-5"
          >
            <div class="w-[80%] flex items-center gap-2">
              <img src="@/assets/search-icon.svg" alt="Search" />
              <input
                v-model="searchInput"
                class="w-full bg-transparent focus:outline-none pr-2 text-[#393840] text-xs sm:text-sm"
                type="text"
                placeholder="Search by project name"
              />
            </div>
          </div>
        </div>
        <!-- Right side -->
        <div class="mt-3 md:mt-0 flex items-center gap-4">
          <!-- Refresh button -->
          <button
            class="flex items-center gap-2 font-normal text-sm text-[#393840] hover:bg-white rounded-lg px-3 py-3"
            :disabled="projectsStore.getIsLoading"
            @click="refreshProjects()"
          >
            <img
              :class="{ 'animate-spin': projectsStore.getIsLoading }"
              src="@/assets/refresh-icon.svg"
              alt="loading-icon"
            />
            Refresh
          </button>
          <form class="w-[160px]">
            <div class="relative w-full bg-transparent">
              <div class="flex gap-1">
                <img src="@/assets/filter-icon.svg" alt="Icon" />
                <!-- Custom select menu -->
                <div
                  class="custom-select"
                  @click="isSelectMenuHandler"
                  @focusout="isSelectedMenu = false"
                >
                  <div class="select-selected">
                    {{
                      selecteStatusFilter
                        ? selecteStatusFilter
                        : "Filter Status"
                    }}
                  </div>
                  <div v-if="isSelectedMenu" class="select-items">
                    <div>
                      <div class="select-item" @click="statusFilter('')">
                        All
                      </div>
                    </div>
                    <div v-for="stage in statusList" :key="stage.id">
                      <div
                        class="select-item"
                        @click="statusFilter(stage.name)"
                      >
                        {{ stage.name }}
                      </div>
                    </div>
                  </div>
                  <img
                    v-if="!isSelectedMenu"
                    class="w-[9px] h-[5px] mt-2 cursor-pointer"
                    src="@/assets/chevron-down.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="w-[9px] h-[5px] mt-2"
                    src="@/assets/chevron-up.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Filter bar by status -->
      <div
        v-if="filteredProjects.length || projectsStore.getIsLoading"
        class="flex flex-col gap-[6px] w-full overflow-x-auto sm:flex-row max-w-[1360px] mx-auto"
      >
        <div
          v-for="stage in filteredColumns"
          :key="stage.id"
          :class="{
            'flex flex-col bg-white p-[6px] rounded-md h-max min-w-[160px] w-[160px]': true,
            'gap-3':
              !closedColumns.includes(stage.id) && filteredProjects?.filter(
                  (item: Project) => item.stage?.id === stage.id
                ).length > 1,
          }"
        >
          <div
            class="flex items-center justify-between cursor-pointer select-none"
            @click="closeColumnToggle(stage.id)"
          >
            <div
              v-if="!projectsStore.getIsLoading"
              :class="`p-1 w-6 h-6 flex items-center justify-center rounded-lg text-xs text-[#393840] font-semibold`"
              :style="{ backgroundColor: `${stage.color}` }"
            >
              {{
                filteredProjects?.filter(
                  (item: Project) => item.stage?.id === stage.id
                ).length
              }}
            </div>
            <h5 class="text-xs text-[#393840] font-medium">
              {{ stage.name }}
            </h5>
            <img
              src="@/assets/caret.svg"
              alt="Icon"
              :class="{
                'cursor-pointer': true,
                'transform rotate-180': closedColumns.includes(stage.id),
              }"
            />
          </div>
          <div
            v-if="projectsStore.getIsLoading"
            class="flex gap-[6px] overflow-auto sm:flex-col mt-3"
          >
            <div
              v-for="index in Math.floor(Math.random() * 5) + 1"
              :key="index"
              class="flex flex-col p-[10px] bg-white rounded-lg border border-[#A7A5AE] min-h-32"
            >
              <CardLoader />
            </div>
          </div>
          <div
            v-else-if="!closedColumns.includes(stage.id) && filteredProjects?.filter((item:Project)=> item.stage?.id === stage.id).length"
            class="flex gap-[6px] overflow-auto sm:flex-col mt-3"
          >
            <div
              v-for="listItem in filteredProjects.filter((item:Project)=> item.stage?.id === stage.id)"
              :key="listItem.id"
              class="w-full"
            >
              <RouterLink
                :to="{ name: 'ProjectDetails', params: { id: listItem.id } }"
              >
                <ProjectCard
                  :project-id="listItem.id"
                  :project-name="listItem.name"
                  :project-status-color="
                  statusList.find(
                    (statusItem: Status) => statusItem.id === listItem.stage.id
                  )?.color || '#A8C5DA'
                "
                  :project-status-name="listItem.stage.name"
                  :project-installation-dates="
                    listItem.installationTermins || []
                  "
                />
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center mt-36">
        <img src="@/assets/no-results/dashboard.svg" alt="No results" />
      </div>
    </div>
  </MainLayout>
</template>
