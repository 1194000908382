<script setup lang="ts">
import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import { toast } from "vue3-toastify";

const email = ref<string>("");
const password = ref<string>("");
const userStore = useUserStore();

const submitting = ref(false);
const hover = ref(false);

const loginUser = async (e: Event) => {
  e.preventDefault();
  if (!email.value || !password.value || submitting.value) {
    toast.error("Invalid credentials");
    return;
  }

  submitting.value = true;

  await userStore.login(email.value, password.value);

  if (userStore.getAccessToken) {
    router.push({ name: "Home" });
  } else {
    toast.error("Invalid credentials");
  }

  submitting.value = false;
};
</script>

<template>
  <div>
    <div
      class="w-full h-screen bg-[url('@/assets/login-background.png')] bg-center bg-no-repeat bg-cover flex justify-center pt-32"
    >
      <div class="w-full px-7 sm:w-[420px]">
        <div class="mb-16">
          <img src="@/assets/logo.svg" alt="Logo" />
        </div>
        <div class="mb-8">
          <h2 class="font-bold text-3xl text-white pb-1">
            Login to your Account
          </h2>
          <h5 class="text-white text-xs">
            Find the details and statuses of all your projects
          </h5>
        </div>
        <!-- Form -->
        <form @submit="loginUser">
          <div class="flex flex-col mb-4">
            <label for="email" class="text-white text-sm pb-[2px] font-semibold"
              >Email</label
            >
            <input
              id="email"
              v-model="email"
              class="text-sm text-white bg-transparent border border-white rounded-lg py-[12px] px-[10px] outline-none"
              type="email"
            />
          </div>
          <div class="flex flex-col mb-8">
            <label
              for="password"
              class="text-white text-sm pb-[2px] font-semibold"
              >Password</label
            >
            <input
              id="password"
              v-model="password"
              class="text-sm text-white bg-transparent border border-white rounded-lg py-[12px] px-[10px] outline-none"
              type="password"
            />
          </div>
          <button
            class="w-full flex items-center justify-center gap-3 text-[#393840] text-lg font-semibold bg-white border-0 rounded-lg py-3 px-[18px] hover:bg-[#0A0564] hover:text-white duration-300 disabled:hover:bg-white disabled:hover:text-[#393840] disabled:cursor-not-allowed"
            type="submit"
            :disabled="submitting"
            @mouseenter="hover = submitting ? false : true"
            @mouseleave="hover = false"
          >
            <span v-if="!submitting" class="flex gap-3">
              <img
                v-if="!hover"
                src="@/assets/login-arrow.svg"
                alt="Arrow icon"
              />
              <img
                v-if="hover"
                src="@/assets/login-arrow-white.svg"
                alt="Arrow icon"
              />
              Login
            </span>
            <SpinnerLoader v-else />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
