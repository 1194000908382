import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mt-6 bg-white rounded-lg px-[12px] py-[12px]" }
const _hoisted_2 = { class: "flex flex-col gap-3" }
const _hoisted_3 = { class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3"
}
const _hoisted_9 = { class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "flex items-center flex-wrap gap-1 text-sm sm:text-base font-normal text-[#393840]" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "text-sm sm:text-base font-normal text-[#393840] flex items-center gap-3" }
const _hoisted_19 = { key: 0 }


import { Status } from "@/types";
import InlineLoader from "../Loaders/InlineLoader.vue";
import { formatStartEndDate } from "@/utils/format-date";
import { statusList } from "@/utils/constants";
import { InstallationTermin } from "@/types";

type Props = {
  loading: boolean;
  projectName: string;
  homeOwner: string | null;
  homeownerNumber: string;
  projectInstallationDates: Array<InstallationTermin>;
  address: string | undefined;
  stage: Status | undefined;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoSection',
  props: {
    loading: { type: Boolean },
    projectName: {},
    homeOwner: {},
    homeownerNumber: {},
    projectInstallationDates: {},
    address: {},
    stage: {}
  },
  setup(__props: any) {

const props = __props;

let startDate = "1970-01-01T00:00:00+00:00";
let endDate = "9999-12-31T23:59:59+00:00";

let installationDates = "";

if (props.projectInstallationDates?.length) {
  props.projectInstallationDates.forEach((installationDate) => {
    if (new Date(installationDate.start) > new Date(startDate)) {
      startDate = installationDate.start;
    }

    if (new Date(installationDate.end) < new Date(endDate)) {
      endDate = installationDate.end;
    }
  });
  installationDates = formatStartEndDate(startDate, endDate);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "pb-6 text-base font-semibold text-[#393840]" }, "Project details", -1)),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _cache[0] || (_cache[0] = _createTextVNode(" Project name: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.projectName), 1))
      ]),
      _createElementVNode("li", _hoisted_6, [
        _cache[1] || (_cache[1] = _createTextVNode(" Homeowner: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.homeOwner ? _ctx.homeOwner : ""), 1))
      ]),
      _createElementVNode("li", _hoisted_9, [
        _cache[2] || (_cache[2] = _createTextVNode(" Phone: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.homeownerNumber), 1))
      ]),
      _createElementVNode("li", _hoisted_12, [
        _cache[3] || (_cache[3] = _createTextVNode(" Installation dates: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(installationDates) || "No installation dates"), 1))
      ]),
      _createElementVNode("li", _hoisted_15, [
        _cache[4] || (_cache[4] = _createTextVNode(" Address: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.address || ""), 1))
      ]),
      _createElementVNode("li", _hoisted_18, [
        _cache[5] || (_cache[5] = _createTextVNode(" Project status: ")),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
              _createVNode(InlineLoader)
            ]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "px-2 rounded-lg text-sm text-center",
              style: _normalizeStyle({
            backgroundColor: `${
              _unref(statusList).find((status) => status.id === _ctx.stage?.id)?.color
            }`,
          })
            }, _toDisplayString(_ctx.stage?.name), 5))
      ])
    ])
  ]))
}
}

})