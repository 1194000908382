<script setup lang="ts">
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
</script>

<template>
  <header
    class="fixed top-0 left-0 z-10 w-full px-6 py-3 bg-white border-b border-[#FCD3AC] flex items-center justify-between"
  >
    <!-- Left side -->
    <div>
      <!-- Logo -->
      <RouterLink to="/">
        <div>
          <img src="@/assets/header-logo.svg" alt="Logo" />
        </div>
      </RouterLink>
    </div>
    <!-- Right side -->
    <div class="flex items-center gap-2">
      <!-- User profile -->
      <div class="flex items-center gap-1">
        <img src="@/assets/user-icon.svg" alt="User icon" />
        <h5 class="text-xs text-[#393840]">
          {{ userStore.getUser?.fullName }}
        </h5>
      </div>
      <!-- Image -->
      <div v-if="userStore.getCustomerLogo">
        <img
          :src="userStore.getCustomerLogo"
          alt="Company Logo"
          class="max-h-6 max-w-20 min-w-20 h-auto"
        />
      </div>
    </div>
  </header>
</template>
