import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/expand-icon.svg'
import _imports_1 from '@/assets/no-results/termins.png'


const _hoisted_1 = { class: "bg-white rounded-xl px-[12px] py-[14px]" }
const _hoisted_2 = { class: "shadow-md rounded-lg" }
const _hoisted_3 = {
  key: 0,
  class: "px-4 py-2"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex items-center gap-2 sm:gap-3 justify-between" }
const _hoisted_6 = { class: "flex flex-wrap gap-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "text-xs sm:text-sm font-normal text-[#393840]" }
const _hoisted_9 = { class: "flex text-xs sm:text-sm items-center gap-1" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 2 }

import { InstallationTermin } from "@/types";

import RowLoader from "../Loaders/RowLoader.vue";
import { formatStartEndDate } from "@/utils/format-date";
import { installationTerminStageList } from "@/utils/constants";

type Props = {
  installationTermins: InstallationTermin[] | undefined;
  loading: boolean;
};

type Emits = {
  (event: "toggleTermins"): void;
  (event: "terminModalHandler", installationTermin: InstallationTermin): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationTerminsSection',
  props: {
    installationTermins: {},
    loading: { type: Boolean }
  },
  emits: ["toggleTermins", "terminModalHandler"],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleTermins')))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("h5", { class: "bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]" }, " Installation Termins ", -1)
      ])),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (index) => {
              return _createElementVNode("div", { key: index }, [
                _createVNode(RowLoader)
              ])
            }), 64))
          ]))
        : (_ctx.installationTermins?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installationTermins, (installationTermin) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: installationTermin.id,
                  class: "px-4 py-2"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h5", {
                        onClick: ($event: any) => (emits('terminModalHandler', installationTermin)),
                        class: "text-sm sm:text-base font-normal text-[#393840] cursor-pointer hover:underline select-none"
                      }, _toDisplayString(installationTermin ? installationTermin.name : ""), 9, _hoisted_7),
                      _createElementVNode("div", {
                        class: "px-2 rounded-lg",
                        style: _normalizeStyle({
                  backgroundColor: _unref(installationTerminStageList).find(
                    (status) => status.name === installationTermin?.stage
                  )?.color,
                })
                      }, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(installationTermin ? installationTermin.stage : ""), 1)
                      ], 4),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(formatStartEndDate)(
                    installationTermin.start as string,
                    installationTermin.end as string
                  )), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        onClick: ($event: any) => (emits('terminModalHandler', installationTermin)),
                        src: _imports_0,
                        alt: "Expand",
                        class: "cursor-pointer rounded-lg hover:bg-[#E3E1EA]"
                      }, null, 8, _hoisted_10)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "No Termins",
                class: "mx-auto"
              }, null, -1)
            ])))
    ])
  ]))
}
}

})