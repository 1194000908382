export const formatStartEndDate = (start: string, end: string) => {
  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const startDay = startDate.getDate();

    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endDay = endDate.getDate();
    // format 31.01.2022
    if (
      startYear === endYear &&
      startMonth === endMonth &&
      startDay === endDay
    ) {
      return `${startDay}.${startMonth}.${startYear}`;
    } else {
      return `${startDay}.${startMonth}.${startYear} - ${endDay}.${endMonth}.${endYear}`;
    }
  } else {
    return "";
  }
};
