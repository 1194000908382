<script setup lang="ts">
import { InstallationTermin } from "@/types";
import { defineProps, defineEmits } from "vue";
import RowLoader from "../Loaders/RowLoader.vue";
import { formatStartEndDate } from "@/utils/format-date";
import { installationTerminStageList } from "@/utils/constants";

type Props = {
  installationTermins: InstallationTermin[] | undefined;
  loading: boolean;
};

type Emits = {
  (event: "toggleTermins"): void;
  (event: "terminModalHandler", installationTermin: InstallationTermin): void;
};

defineProps<Props>();
const emits = defineEmits<Emits>();
</script>

<template>
  <div class="bg-white rounded-xl px-[12px] py-[14px]">
    <!-- Dropdown -->
    <div class="shadow-md rounded-lg">
      <div @click="emits('toggleTermins')">
        <h5
          class="bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]"
        >
          Installation Termins
        </h5>
      </div>
      <div v-if="loading" class="px-4 py-2">
        <div v-for="index in 2" :key="index">
          <RowLoader />
        </div>
      </div>
      <div v-else-if="installationTermins?.length">
        <div
          v-for="installationTermin in installationTermins"
          :key="installationTermin.id"
          class="px-4 py-2"
        >
          <div class="flex items-center gap-2 sm:gap-3 justify-between">
            <div class="flex flex-wrap gap-2">
              <h5
                @click="emits('terminModalHandler', installationTermin)"
                class="text-sm sm:text-base font-normal text-[#393840] cursor-pointer hover:underline select-none"
              >
                {{ installationTermin ? installationTermin.name : "" }}
              </h5>
              <div
                class="px-2 rounded-lg"
                :style="{
                  backgroundColor: installationTerminStageList.find(
                    (status) => status.name === installationTermin?.stage
                  )?.color,
                }"
              >
                <span class="text-xs sm:text-sm font-normal text-[#393840]">
                  {{ installationTermin ? installationTermin.stage : "" }}
                </span>
              </div>
              <div class="flex text-xs sm:text-sm items-center gap-1">
                {{
                  formatStartEndDate(
                    installationTermin.start as string,
                    installationTermin.end as string
                  )
                }}
              </div>
            </div>
            <div>
              <img
                @click="emits('terminModalHandler', installationTermin)"
                src="@/assets/expand-icon.svg"
                alt="Expand"
                class="cursor-pointer rounded-lg hover:bg-[#E3E1EA]"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img
          src="@/assets/no-results/termins.png"
          alt="No Termins"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
</template>
