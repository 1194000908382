<script setup lang="ts">
import { computed, defineProps, defineEmits, ref, watch, nextTick } from "vue";
import type { ProjectComment } from "@/types";
import client from "@/utils/axios";
import CommentLoader from "@/components/Loaders/CommentLoader.vue";

type Props = {
  projectId: string;
  loading: boolean;
  comments: Array<ProjectComment>;
};

type Emits = {
  (event: "refreshComments"): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const comment = ref("");
const commentSubmitting = ref(false);
const commentSectionWrapper = ref<HTMLElement | null>(null);

const commentList = computed(() => props.comments);

const submitComment = (e: Event) => {
  e.preventDefault();
  commentSubmitting.value = true;
  client
    .post(`/projects/${props.projectId}/comments`, {
      comment: comment.value,
    })
    .then(async () => {
      emits("refreshComments");
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      comment.value = "";
      commentSubmitting.value = false;
    });
};

watch(
  () => props.comments,
  () => {
    nextTick(() => {
      if (commentSectionWrapper.value) {
        commentSectionWrapper.value.scrollTop =
          commentSectionWrapper.value.scrollHeight;
      }
    });
  }
);
</script>

<template>
  <div class="mt-6 bg-white rounded-lg p-[12px]">
    <h4 class="pb-6 text-base font-semibold text-[#393840]">
      Project Comments
    </h4>
    <div class="max-h-[400px] overflow-y-auto" ref="commentSectionWrapper">
      <div v-if="loading" class="w-full mb-10">
        <div
          v-for="index in Math.floor(Math.random() * 6) + 3"
          :key="index"
          class="w-full flex flex-col"
        >
          <CommentLoader :align-right="index % 2 === 0" />
        </div>
      </div>
      <div v-else-if="commentList?.length">
        <div
          v-for="(message, index) in commentList"
          :key="index"
          class="w-full flex flex-col"
        >
          <div v-if="!message.isAdmin" class="w-[80%] self-start mb-7">
            <div>
              <div class="flex flex-col-reverse sm:flex-row">
                <div class="flex items-end sm:justify-end mr-3">
                  <img
                    class="min-w-16"
                    src="@/assets/header-img.svg"
                    alt="Company Logo"
                  />
                </div>
                <div>
                  <h4
                    class="text-sm text-[#393840] font-normal py-1 px-2 rounded-t-md rounded-br-md bg-[#F1F0F5]"
                    v-html="message.text"
                  ></h4>
                  <p class="text-[10px] text-[#A7A5AE] pt-1">
                    {{ message.postedBy }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="w-[80%] self-end mb-7 mr-2">
            <div class="flex flex-col justify-end gap-1 sm:flex-row">
              <div class="flex flex-col">
                <h4
                  class="w-full text-sm text-[#393840] font-normal py-1 px-2 rounded-t-md rounded-bl-md bg-[#E9E8F9]"
                  v-html="message.text"
                ></h4>
                <p class="text-[10px] text-[#A7A5AE] font-normal pt-1 self-end">
                  {{ message.postedBy }}
                </p>
              </div>
              <div class="flex items-end justify-end ml-3">
                <img
                  class="min-w-14"
                  src="@/assets/header-logo.svg"
                  alt="Admin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img
          src="@/assets/no-results/comments.png"
          alt="No Comments"
          class="mx-auto"
        />
      </div>
    </div>
    <form :class="{ 'w-full': true, hidden: loading }" @submit="submitComment">
      <div
        class="flex items-center justify-between border p-[10px] border-[#E3E1EA] rounded-lg"
      >
        <div class="w-full">
          <input
            type="text"
            v-model="comment"
            placeholder="Type your comment"
            class="w-full text-sm text-[#393840] font-normal placeholder:text-[#898790] pr-[10px] focus:outline-none"
          />
        </div>
        <div>
          <button
            type="submit"
            class="bg-transparent text-base font-semibold px-[10px] py-[6px] lowercase text-[#393840] border-2 border-[#393840] rounded-lg cursor-pointer hover:bg-[#A3B1FF] disabled:hover:bg-white disabled:cursor-not-allowed"
            :disabled="commentSubmitting"
          >
            <span class="text-sm font-semibold text-[#393840]">
              {{ commentSubmitting ? "Sending..." : "Send" }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
