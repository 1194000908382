<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { useRoute } from "vue-router";

const showMenu = ref(false);
const userStore = useUserStore();
const route = useRoute();

onBeforeMount(() => {
  userStore.checkAuth();
});

const toggleMenuHandler = () => {
  showMenu.value = !showMenu.value;
};

const logoutUser = () => {
  userStore.logout();
  router.push({ name: "Login" });
};

const isSelected = (menuName: string) => {
  return menuName === route.name;
};
</script>

<template>
  <div
    class=""
    :class="
      showMenu
        ? 'fixed min-w-full h-full top-[42px] left-0 z-50 sm:relative sm:top-[42px] sm:min-w-44 min-h-[calc(100vh-48px)]'
        : 'min-w-20 sm:min-w-20 min-h-[calc(100vh-48px)]'
    "
  >
    <!-- Menu -->
    <div
      :class="
        showMenu
          ? 'fixed top-[48px] py-6 h-[calc(100vh-48px)] flex flex-col gap-2 items-start justify-between'
          : 'fixed top-[48px] left-[16px] sm:left-[24px] py-6 h-[calc(100vh-48px)] flex flex-col gap-2 items-center justify-between'
      "
    >
      <!-- Top -->
      <div>
        <div
          @click="toggleMenuHandler"
          :class="{
            'fixed top-14 right-4 sm:relative sm:top-0 mb-1 sm:right-0 sm:ml-[132px] w-8 h-7 flex items-center justify-center cursor-pointer rounded-lg hover:bg-white':
              showMenu,
            'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
              !showMenu,
          }"
        >
          <img v-if="showMenu" src="@/assets/menu-icon-1.svg" alt="" />
          <img v-else src="@/assets/double-arrow-right.svg" alt="" />
        </div>
        <!-- Projects menu -->
        <RouterLink to="/">
          <div
            :class="{
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu,
              'bg-white': isSelected('Home') || isSelected('ProjectDetails'),
            }"
          >
            <img src="@/assets/menu-icon-2.svg" alt="Icon" />
            <h5
              :class="
                showMenu ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              "
            >
              Projects
            </h5>
          </div>
        </RouterLink>
        <!-- Downloads menu -->
        <RouterLink to="/downloads">
          <div
            :class="{
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu,
              'bg-white': isSelected('Downloads'),
            }"
          >
            <img src="@/assets/menu-icon-3.svg" alt="Icon" />
            <h5
              :class="
                showMenu ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              "
            >
              My downloads
            </h5>
          </div>
        </RouterLink>
        <!-- Get Help Menu -->
        <RouterLink to="/help">
          <div
            :class="{
              'h-8 flex items-center justify-start gap-1 ml-5 mb-1 px-2 py-1 cursor-pointer rounded-lg hover:bg-white':
                showMenu,
              'w-8 h-7 flex items-center justify-center mb-1 cursor-pointer rounded-lg hover:bg-white':
                !showMenu,
              'bg-white': isSelected('Help'),
            }"
          >
            <img src="@/assets/menu-icon-4.svg" alt="Icon" />
            <h5
              :class="
                showMenu ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
              "
            >
              Get help
            </h5>
          </div>
        </RouterLink>
      </div>
      <!-- Logout -->
      <div
        :class="
          showMenu
            ? 'h-8 flex items-center justify-start gap-2 ml-5 px-2 py-1 cursor-pointer rounded-lg hover:bg-white'
            : 'w-8 h-7 flex items-center justify-center cursor-pointer rounded-lg hover:bg-white'
        "
        @click="logoutUser"
      >
        <img src="@/assets/logout-icon.svg" alt="Icon" />
        <h5
          :class="
            showMenu ? 'flex text-sm text-[#393840] leading-5 ' : 'hidden'
          "
        >
          Logout
        </h5>
      </div>
    </div>
  </div>
</template>
