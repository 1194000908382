import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/header-logo.svg'
import _imports_1 from '@/assets/user-icon.svg'


const _hoisted_1 = { class: "fixed top-0 left-0 z-10 w-full px-6 py-3 bg-white border-b border-[#FCD3AC] flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = { class: "flex items-center gap-1" }
const _hoisted_4 = { class: "text-xs text-[#393840]" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]

import { useUserStore } from "@/stores/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainHeader',
  setup(__props) {

const userStore = useUserStore();

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_RouterLink, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Logo"
            })
          ], -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_1,
          alt: "User icon"
        }, null, -1)),
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_unref(userStore).getUser?.fullName), 1)
      ]),
      (_unref(userStore).getCustomerLogo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _unref(userStore).getCustomerLogo,
              alt: "Company Logo",
              class: "max-h-6 max-w-20 min-w-20 h-auto"
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})