<script setup lang="ts">
import "/custom.css";
import { defineProps, defineEmits, ref } from "vue";
import type { ProjectDocument } from "@/types";
import RowLoader from "../Loaders/RowLoader.vue";
import client from "@/utils/axios";
import { toast } from "vue3-toastify";

type Props = {
  documents: ProjectDocument[] | undefined;
  loading: boolean;
  uploadingDocument: boolean;
  documentsToggle: boolean;
  projectName?: string | undefined;
  projectId?: string;
};

type Emits = {
  (event: "toggleDocuments"): void;
  (event: "uploadDocument", file: File): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const selectedDocument = ref([]);
const uploadFile = ref<HTMLElement | null>(null);

const downloadingDocument = ref(false);

const uploadFileInitiate = () => {
  if (uploadFile.value) {
    uploadFile.value.click();
  }
};

const uploadFileHandler = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    emits("uploadDocument", files[0]);
  }
};

const downloadDocumentHandler = async () => {
  let documentsForDownload: any = [];
  if (selectedDocument.value.length > 0) {
    documentsForDownload = [...selectedDocument.value];
  } else {
    documentsForDownload = props.documents?.map(
      (item: ProjectDocument) => item.id
    );
  }
  downloadingDocument.value = true;
  const response = await client.post("/projects/prepare-documents", {
    data: {
      project: props.projectName ? props.projectName : "",
      documents: documentsForDownload,
      projectId: props.projectId,
    },
  });

  if (response && response.status === 200) {
    toast.success(
      "Documents are being prepared for download. You can check progress on downloads page."
    );
  }

  if (response && response.status >= 400) {
    toast.error(response.statusText);
  }
  selectedDocument.value = [];
  downloadingDocument.value = false;
};
</script>

<template>
  <div class="bg-white rounded-xl px-[12px] py-[14px] mt-6">
    <div class="shadow-md rounded-lg">
      <div @click="emits('toggleDocuments')">
        <h5
          class="bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]"
        >
          Documents
        </h5>
      </div>
      <div v-if="loading">
        <div
          v-for="index in Math.floor(Math.random() * 6) + 3"
          :key="index"
          class="px-4 py-3"
        >
          <RowLoader />
        </div>
      </div>
      <div v-else-if="documents?.length">
        <div v-if="props.documentsToggle">
          <div
            v-for="(document, index) in documents"
            :key="index"
            class="px-4 py-3 hover:bg-[#F1F0F5] duration-200"
          >
            <label
              class="flex items-center justify-between cursor-pointer break-words gap-2"
            >
              <a
                :href="document.url"
                class="text-sm sm:text-base font-normal text-[#616068] hover:underline"
                target="_blank"
              >
                {{ document ? document.name : "" }}
              </a>
              <div class="flex gap-3">
                <a :href="document.url" target="_blank">
                  <img
                    src="@/assets/open-in-new-tab.svg"
                    alt="open icon"
                    class="hover:-translate-y-0.5 hover:translate-x-0.5 duration-200"
                  />
                </a>
                <input
                  v-model="selectedDocument"
                  type="checkbox"
                  :value="document.id"
                />
              </div>
            </label>
          </div>
          <!-- Upload and download documents -->
          <div v-if="documents">
            <div
              v-if="!loading"
              class="flex items-center flex-wrap gap-3 py-3 px-4 mt-6"
            >
              <button
                class="w-full sm:w-auto flex items-center justify-center gap-2 sm:gap-3 border-2 text-xs sm:text-sm font-semibold text-[#393840] border-black bg-white rounded-lg py-[8px] sm:py-[11px] px-[14px] sm:px-[18px] cursor-pointer hover:bg-[#A3B1FF] duration-200 disabled:hover:bg-white disabled:cursor-not-allowed"
                :disabled="uploadingDocument || downloadingDocument"
                @click="uploadFileInitiate"
              >
                <span v-if="!uploadingDocument" class="flex gap-2">
                  <img
                    class="w-4 h-4 sm:w-5 sm:h-5"
                    src="@/assets/upload-icon.svg"
                    alt="upload icon"
                  />
                  Upload Document
                </span>
                <span v-else class="flex gap-2">
                  <img src="@/assets/dots-icon.svg" alt="loading icon" />
                  Uploading...
                </span>
              </button>
              <input
                ref="uploadFile"
                type="file"
                class="hidden"
                @change="uploadFileHandler"
              />
              <button
                class="w-full sm:w-auto flex items-center justify-center gap-2 sm:gap-3 border-2 text-xs sm:text-sm font-semibold text-[#393840] border-black bg-white rounded-lg py-[8px] sm:py-[11px] px-[14px] sm:px-[18px] cursor-pointer hover:bg-[#A3B1FF] duration-200 disabled:hover:bg-white disabled:cursor-not-allowed"
                :disabled="uploadingDocument || downloadingDocument"
                @click="downloadDocumentHandler"
              >
                <span v-if="!downloadingDocument" class="flex gap-2">
                  <img
                    class="w-4 h-4 sm:w-5 sm:h-5"
                    src="@/assets/download-icon.svg"
                    alt="download icon"
                  />
                  Download
                </span>
                <span v-else class="flex gap-2">
                  <img src="@/assets/dots-icon.svg" alt="loading icon" />
                  Downloading...
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img
          src="@/assets/no-results/documents.png"
          alt="No Documents"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
</template>
