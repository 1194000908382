import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full bg-[#F1F0F5]" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "h-screen w-full overflow-x-hidden pt-[42px]" }

import MainHeader from "../components/MainHeader.vue";
import SideMenu from "../components/SideMenu.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MainHeader),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SideMenu),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})