<script setup lang="ts"></script>

<template>
  <div
    role="status"
    class="flex items-center align-middle justify-between animate-pulse w-full"
  >
    <div>
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-48 my-1.5"
      ></div>
    </div>
    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-4"></div>
  </div>
</template>
