import { defineStore } from "pinia";
import { SingleProjectStore, ProjectDetail } from "@/types";
import client from "@/utils/axios";
import router from "@/router";

export const useSingleProjectStore = defineStore("singleProject", {
  state: () => ({
    projects: <Array<SingleProjectStore>>[],
  }),
  actions: {
    async getProject(id: string) {
      const project = this.projects.find((project) => project.id === id);

      if (!project) {
        await this.fetchSingleProject(id);
      }

      return (
        this.projects.find((project) => project.id === id)?.project || null
      );
    },
    async fetchSingleProject(id: string) {
      try {
        const projectResponse = await client.get(`/projects/${id}`);

        const project: ProjectDetail = projectResponse.data;

        this.projects.push({
          id: project.id,
          project,
        });
      } catch (error) {
        router.push({ name: "Home" });
      }
    },
    async fetchSingleProjectComments(id: string) {
      try {
        const response = await client.get(`/projects/${id}/comments`);
        return response.data;
      } catch (error) {
        router.push({ name: "Home" });
      }
    },
    async fetchSingleProjectDocuments(id: string) {
      try {
        const response = await client.get(`/projects/${id}/documents`);
        return response.data;
      } catch (error) {
        router.push({ name: "Home" });
      }
    },
    clearSingleProject() {
      this.projects = [];
    },
  },
  // persist: true,
});
