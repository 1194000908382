// src/axios.js
import axios from "axios";
import { useUserStore } from "@/stores/user";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT + "/api",
  headers: { "Content-Type": "application/json" },
});

// Request interceptor
client.interceptors.request.use(
  (config) => {
    const userStore = useUserStore();
    if (config.headers) {
      config.headers["x-supabase-auth"] = `${userStore.getAccessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      const userStore = useUserStore();
      userStore.logout();
      window.location.href = "/login";
      console.warn("[Token missing or invalid]");
    }
    return Promise.reject(error);
  }
);

export default client;
