<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const subject = ref("");
const content = ref("");
const messageSending = ref(false);

const submitForm = async (e: Event) => {
  e.preventDefault();
  if (!subject.value || !content.value) {
    toast.error("Please fill in all fields");
    return;
  }

  if (messageSending.value) {
    return;
  }

  messageSending.value = true;

  try {
    await client.post("/contact/customer-dashboard", {
      subject: subject.value,
      content: content.value,
    });

    toast.success("Message sent successfully");

    subject.value = "";
    content.value = "";
    messageSending.value = false;
  } catch (error: any) {
    console.log(error);
    toast.error(error);
  }
};
</script>

<template>
  <MainLayout>
    <div class="p-4 w-full flex items-center justify-center sm:p-6">
      <div class="flex flex-col px-6 py-5 bg-white rounded-lg">
        <div class="mb-5">
          <h4 class="text-base font-semibold text-[#393840]">Smalt support</h4>
          <p class="text-sm font-normal text-[#393840]">
            Get in contact with our product team for any questions or feature
            requests
          </p>
        </div>
        <form class="flex flex-col items-start" @submit="submitForm">
          <input
            v-model="subject"
            class="p-[10px] mb-3 w-full border border-[#C5C3CC] rounded-md font-normal text-sm focus:outline-none text-black"
            type="text"
            placeholder="Subject"
          />
          <textarea
            v-model="content"
            class="p-[10px] w-full border border-[#C5C3CC] rounded-md font-normal text-sm resize-none focus:outline-none text-black"
            cols="50"
            rows="5"
            placeholder="Ask your question..."
          ></textarea>
          <button
            type="submit"
            class="w-full sm:w-52 flex items-center justify-center gap-3 mt-5 p-2 border-2 border-[#393840] text-[#393840] text-base font-semibold rounded-[7px] hover:bg-[#857FED] disabled:bg-white disabled:text-[#393840] disabled:hover:bg-white disabled:hover:text-[#393840] disabled:cursor-not-allowed"
            :disabled="messageSending"
          >
            <img
              v-if="messageSending"
              src="@/assets/dots-icon.svg"
              alt="loading icons"
            />
            <span class="text-sm font-semibold text-[#393840]">
              {{ messageSending ? "Sending..." : "Send" }}
            </span>
          </button>
        </form>
      </div>
    </div>
  </MainLayout>
</template>
