export const getProjectStatusColor = (color: any) => {
  console.log(color);
  switch (color) {
    case "blue":
      return "#B1E3FF";
    case "green":
      return "#BAEDBD";
    default:
      return "#A8C5DA";
  }
};

export const getDownloadStatusColor = (status: string) => {
  switch (status) {
    case "in-progress":
      return "#A8C5DA";
    case "ready-to-download":
      return "#B1E3FF";
    default:
      return "#E5ECF6";
  }
};

export const getDownloadStatusName = (status: string) => {
  switch (status) {
    case "in-progress":
      return "In Progress";
    case "ready-to-download":
      return "Ready to Download";
    case "downloaded":
      return "Downloaded";
    default:
      return status;
  }
};
