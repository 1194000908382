<script setup lang="ts">
import { defineProps } from "vue";
import { formatStartEndDate } from "@/utils/format-date";

type Props = {
  projectId: string;
  projectName: string;
  projectStatusName: string;
  projectStatusColor: string;
  projectInstallationDates: Array<any>;
};

const props = defineProps<Props>();

let startDate = "9999-12-31T23:59:59+00:00";
let endDate = "1970-01-01T00:00:00+00:00";

let installationDates = "";

if (props.projectInstallationDates?.length) {
  props.projectInstallationDates.forEach((installationDate) => {
    if (!installationDate.start || !installationDate.end) {
      return;
    }
    if (new Date(installationDate.start) < new Date(startDate)) {
      startDate = installationDate.start;
    }

    if (new Date(installationDate.end) > new Date(endDate)) {
      endDate = installationDate.end;
    }
  });
  // we are not on default dates
  if (
    startDate !== "9999-12-31T23:59:59+00:00" &&
    endDate !== "1970-01-01T00:00:00+00:00"
  ) {
    installationDates = formatStartEndDate(startDate, endDate);
  }
}
</script>

<template>
  <div
    class="flex flex-col p-[8px] bg-white rounded-lg border border-[#F1F0F5] min-h-32 justify-between drop-shadow"
  >
    <div>
      <!-- Card status -->
      <div class="flex items-center justify-between mb-2">
        <div
          :class="`px-2 py-[2px] rounded-lg text-[10px] text-[#393840] font-normal`"
          :style="{ backgroundColor: `${props.projectStatusColor}` }"
        >
          {{ props.projectStatusName }}
        </div>
        <RouterLink
          class="cursor-pointer rounded-lg hover:bg-[#E3E1EA] p-1"
          :to="{ name: 'ProjectDetails', params: { id: props.projectId } }"
        >
          <div>
            <img src="@/assets/card-icon.svg" alt="Icon" />
          </div>
        </RouterLink>
      </div>
      <!-- Title -->
      <h2 class="text-sm text-[#393840] font-medium">
        {{ props.projectName }}
      </h2>
    </div>
    <!-- Dates -->
    <div v-if="installationDates.length">
      <h4 class="text-xs text-[#A7A5AE] font-normal">Installation dates:</h4>
      <h5
        class="text-[#393840] text-xs font-normal"
        v-html="installationDates"
      ></h5>
    </div>
    <div v-else>
      <h4 class="text-xs text-[#A7A5AE] font-normal">No installation dates</h4>
    </div>
  </div>
</template>
