import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  role: "status",
  class: "animate-pulse"
}


type Props = {
  alignRight: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CommentLoader',
  props: {
    alignRight: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'flex items-center mt-4': true,
        'flex-row-reverse': props.alignRight,
      })
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass({
          'w-10 h-10 me-3 text-gray-200 dark:text-gray-700': true,
          'ms-3': props.alignRight,
        }),
        "aria-hidden": "true",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 20 20"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("path", { d: "M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" }, null, -1)
      ]), 2)),
      _createElementVNode("div", {
        class: _normalizeClass({
          'flex flex-col': true,
          'items-end': props.alignRight,
        })
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2" }, null, -1),
        _createElementVNode("div", { class: "w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" }, null, -1)
      ]), 2)
    ], 2)
  ]))
}
}

})