import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/dots-icon.svg'


const _hoisted_1 = { class: "p-4 w-full flex items-center justify-center sm:p-6" }
const _hoisted_2 = { class: "flex flex-col px-6 py-5 bg-white rounded-lg" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "loading icons"
}
const _hoisted_5 = { class: "text-sm font-semibold text-[#393840]" }

import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { ref } from "vue";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'GetHelpVIew',
  setup(__props) {

const subject = ref("");
const content = ref("");
const messageSending = ref(false);

const submitForm = async (e: Event) => {
  e.preventDefault();
  if (!subject.value || !content.value) {
    toast.error("Please fill in all fields");
    return;
  }

  if (messageSending.value) {
    return;
  }

  messageSending.value = true;

  try {
    await client.post("/contact/customer-dashboard", {
      subject: subject.value,
      content: content.value,
    });

    toast.success("Message sent successfully");

    subject.value = "";
    content.value = "";
    messageSending.value = false;
  } catch (error: any) {
    console.log(error);
    toast.error(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-5" }, [
            _createElementVNode("h4", { class: "text-base font-semibold text-[#393840]" }, "Smalt support"),
            _createElementVNode("p", { class: "text-sm font-normal text-[#393840]" }, " Get in contact with our product team for any questions or feature requests ")
          ], -1)),
          _createElementVNode("form", {
            class: "flex flex-col items-start",
            onSubmit: submitForm
          }, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((subject).value = $event)),
              class: "p-[10px] mb-3 w-full border border-[#C5C3CC] rounded-md font-normal text-sm focus:outline-none text-black",
              type: "text",
              placeholder: "Subject"
            }, null, 512), [
              [_vModelText, subject.value]
            ]),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((content).value = $event)),
              class: "p-[10px] w-full border border-[#C5C3CC] rounded-md font-normal text-sm resize-none focus:outline-none text-black",
              cols: "50",
              rows: "5",
              placeholder: "Ask your question..."
            }, null, 512), [
              [_vModelText, content.value]
            ]),
            _createElementVNode("button", {
              type: "submit",
              class: "w-full sm:w-52 flex items-center justify-center gap-3 mt-5 p-2 border-2 border-[#393840] text-[#393840] text-base font-semibold rounded-[7px] hover:bg-[#857FED] disabled:bg-white disabled:text-[#393840] disabled:hover:bg-white disabled:hover:text-[#393840] disabled:cursor-not-allowed",
              disabled: messageSending.value
            }, [
              (messageSending.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_5, _toDisplayString(messageSending.value ? "Sending..." : "Send"), 1)
            ], 8, _hoisted_3)
          ], 32)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})