import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/header-img.svg'
import _imports_1 from '@/assets/header-logo.svg'
import _imports_2 from '@/assets/no-results/comments.png'


const _hoisted_1 = { class: "mt-6 bg-white rounded-lg p-[12px]" }
const _hoisted_2 = {
  key: 0,
  class: "w-full mb-10"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "w-[80%] self-start mb-7"
}
const _hoisted_5 = { class: "flex flex-col-reverse sm:flex-row" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-[10px] text-[#A7A5AE] pt-1" }
const _hoisted_8 = {
  key: 1,
  class: "w-[80%] self-end mb-7 mr-2"
}
const _hoisted_9 = { class: "flex flex-col justify-end gap-1 sm:flex-row" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "text-[10px] text-[#A7A5AE] font-normal pt-1 self-end" }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { class: "flex items-center justify-between border p-[10px] border-[#E3E1EA] rounded-lg" }
const _hoisted_15 = { class: "w-full" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "text-sm font-semibold text-[#393840]" }

import { computed, ref, watch, nextTick } from "vue";
import type { ProjectComment } from "@/types";
import client from "@/utils/axios";
import CommentLoader from "@/components/Loaders/CommentLoader.vue";

type Props = {
  projectId: string;
  loading: boolean;
  comments: Array<ProjectComment>;
};

type Emits = {
  (event: "refreshComments"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CommentsSection',
  props: {
    projectId: {},
    loading: { type: Boolean },
    comments: {}
  },
  emits: ["refreshComments"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const comment = ref("");
const commentSubmitting = ref(false);
const commentSectionWrapper = ref<HTMLElement | null>(null);

const commentList = computed(() => props.comments);

const submitComment = (e: Event) => {
  e.preventDefault();
  commentSubmitting.value = true;
  client
    .post(`/projects/${props.projectId}/comments`, {
      comment: comment.value,
    })
    .then(async () => {
      emits("refreshComments");
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      comment.value = "";
      commentSubmitting.value = false;
    });
};

watch(
  () => props.comments,
  () => {
    nextTick(() => {
      if (commentSectionWrapper.value) {
        commentSectionWrapper.value.scrollTop =
          commentSectionWrapper.value.scrollHeight;
      }
    });
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "pb-6 text-base font-semibold text-[#393840]" }, " Project Comments ", -1)),
    _createElementVNode("div", {
      class: "max-h-[400px] overflow-y-auto",
      ref_key: "commentSectionWrapper",
      ref: commentSectionWrapper
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.floor(Math.random() * 6) + 3, (index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "w-full flex flex-col"
              }, [
                _createVNode(CommentLoader, {
                  "align-right": index % 2 === 0
                }, null, 8, ["align-right"])
              ]))
            }), 128))
          ]))
        : (commentList.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commentList.value, (message, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "w-full flex flex-col"
                }, [
                  (!message.isAdmin)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_5, [
                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex items-end sm:justify-end mr-3" }, [
                              _createElementVNode("img", {
                                class: "min-w-16",
                                src: _imports_0,
                                alt: "Company Logo"
                              })
                            ], -1)),
                            _createElementVNode("div", null, [
                              _createElementVNode("h4", {
                                class: "text-sm text-[#393840] font-normal py-1 px-2 rounded-t-md rounded-br-md bg-[#F1F0F5]",
                                innerHTML: message.text
                              }, null, 8, _hoisted_6),
                              _createElementVNode("p", _hoisted_7, _toDisplayString(message.postedBy), 1)
                            ])
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("h4", {
                              class: "w-full text-sm text-[#393840] font-normal py-1 px-2 rounded-t-md rounded-bl-md bg-[#E9E8F9]",
                              innerHTML: message.text
                            }, null, 8, _hoisted_11),
                            _createElementVNode("p", _hoisted_12, _toDisplayString(message.postedBy), 1)
                          ]),
                          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-end justify-end ml-3" }, [
                            _createElementVNode("img", {
                              class: "min-w-14",
                              src: _imports_1,
                              alt: "Admin"
                            })
                          ], -1))
                        ])
                      ]))
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_2,
                alt: "No Comments",
                class: "mx-auto"
              }, null, -1)
            ])))
    ], 512),
    _createElementVNode("form", {
      class: _normalizeClass({ 'w-full': true, hidden: _ctx.loading }),
      onSubmit: submitComment
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((comment).value = $event)),
            placeholder: "Type your comment",
            class: "w-full text-sm text-[#393840] font-normal placeholder:text-[#898790] pr-[10px] focus:outline-none"
          }, null, 512), [
            [_vModelText, comment.value]
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            type: "submit",
            class: "bg-transparent text-base font-semibold px-[10px] py-[6px] lowercase text-[#393840] border-2 border-[#393840] rounded-lg cursor-pointer hover:bg-[#A3B1FF] disabled:hover:bg-white disabled:cursor-not-allowed",
            disabled: commentSubmitting.value
          }, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(commentSubmitting.value ? "Sending..." : "Send"), 1)
          ], 8, _hoisted_16)
        ])
      ])
    ], 34)
  ]))
}
}

})