import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/download-icon.svg'
import _imports_1 from '@/assets/no-results/downloads.png'


const _hoisted_1 = { class: "p-4 sm:p-6" }
const _hoisted_2 = { class: "bg-white rounded-xl px-[12px] py-[14px] w-full lg:w-[70%] 2xl:w-[50%]" }
const _hoisted_3 = { class: "shadow-md rounded-lg" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center justify-center"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex gap-2 flex-col sm:flex-row items-start sm:items-center" }
const _hoisted_7 = { class: "text-sm sm:text-base font-semibold text-[#616068]" }
const _hoisted_8 = { class: "text-xs sm:text-sm" }
const _hoisted_9 = ["href", "onClick"]
const _hoisted_10 = { key: 2 }

import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { onBeforeMount, ref } from "vue";
import type { ProjectDownload } from "@/types";
import {
  getDownloadStatusColor,
  getDownloadStatusName,
} from "@/utils/status-color";
import DownloadLoader from "@/components/Loaders/DownloadLoader.vue";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadsView',
  setup(__props) {

const downloadData = ref<ProjectDownload[]>();

const formattedDate = (date: string) => {
  return new Date(date).toLocaleDateString("de");
};

const loading = ref(false);

const getDownloadData = async () => {
  loading.value = true;
  const response = await client.get("/projects/download-documents");

  if (response.status >= 400) {
    toast.error(response.statusText);
  }
  downloadData.value = response.data;
  loading.value = false;
};

onBeforeMount(() => {
  getDownloadData();
});

const downloadHandler = async (id: string) => {
  const response = await client.put(`/projects/mark-as-downloaded/${id}`);
  if (response.status === 200) {
    getDownloadData();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("div", null, [
              _createElementVNode("h5", { class: "bg-[#E3E1EA] rounded-t-lg px-3 py-3 cursor-pointer text-base font-semibold text-[#393840]" }, " My downloads ")
            ], -1)),
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
                    return _createElementVNode("div", {
                      key: index,
                      class: _normalizeClass(["flex items-center justify-between px-3 py-3 w-full", index % 2 !== 0 ? 'bg-[#F1F0F5]' : 'bg-white'])
                    }, [
                      _createVNode(DownloadLoader)
                    ], 2)
                  }), 64))
                ]))
              : (downloadData.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(downloadData.value, (download, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: download.id,
                        class: _normalizeClass(["flex items-center justify-between px-3 py-3", index % 2 !== 0 ? 'bg-[#F1F0F5]' : 'bg-white'])
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, _toDisplayString(download.name.split("_")[0]), 1),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(formattedDate(download.date)), 1),
                          _createElementVNode("div", {
                            class: _normalizeClass(`text-xs sm:text-sm text-center font-normal text-[#616068] capitalize rounded-md py-[2px] px-2`),
                            style: _normalizeStyle({
                    backgroundColor: `${_unref(getDownloadStatusColor)(
                      download.status
                    )}`,
                  })
                          }, _toDisplayString(_unref(getDownloadStatusName)(download.status)), 5)
                        ]),
                        (download.status !== 'in-progress')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: download.url,
                              target: "_blank",
                              class: "w-5 h-5 ml-1 sm:w-7 sm:h-7 flex items-center justify-center rounded-lg hover:bg-[#E3E1EA]",
                              onClick: ($event: any) => (downloadHandler(download.id))
                            }, _cache[0] || (_cache[0] = [
                              _createElementVNode("img", {
                                src: _imports_0,
                                alt: "Download icon"
                              }, null, -1)
                            ]), 8, _hoisted_9))
                          : _createCommentVNode("", true)
                      ], 2))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "No downloads",
                      class: "mx-auto"
                    }, null, -1)
                  ])))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})