import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/close-icon.svg'
import _imports_1 from '@/assets/full-calendar-icon.svg'
import _imports_2 from '@/assets/employees-icon.svg'
import _imports_3 from '@/assets/no-results/installation-steps.png'


const _hoisted_1 = { class: "flex flex-col lg:h-[83vh] overflow-hidden" }
const _hoisted_2 = { class: "min-h-24" }
const _hoisted_3 = { class: "flex justify-between mb-4" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col justify-center mt-5"
}
const _hoisted_6 = {
  key: 1,
  class: "text-base font-semibold text-[#393840]"
}
const _hoisted_7 = {
  key: 2,
  class: "flex flex-col justify-center mt-5"
}
const _hoisted_8 = { class: "flex items-center gap-2" }
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col justify-center"
}
const _hoisted_10 = {
  key: 1,
  class: "flex items-center gap-1"
}
const _hoisted_11 = { class: "flex items-center gap-2" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col justify-center"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-col justify-center mt-5"
}
const _hoisted_15 = {
  key: 1,
  class: "lg:min-h-[calc(83vh-96px)] overflow-auto"
}
const _hoisted_16 = { class: "pb-2 text-base font-semibold text-[#393840]" }
const _hoisted_17 = { class: "w-full flex items-center gap-4 overflow-auto" }
const _hoisted_18 = { class: "w-[350px] h-[350px] relative" }
const _hoisted_19 = ["id"]
const _hoisted_20 = ["src", "alt", "onLoad"]
const _hoisted_21 = {
  key: 2,
  class: "m-auto"
}

import { onBeforeMount, ref, watch } from "vue";
import type { InstallationTermin } from "@/types";
import TerminLoader from "../Loaders/TerminLoader.vue";
import ImageLoader from "../Loaders/ImageLoader.vue";
import client from "@/utils/axios";
import { formatStartEndDate } from "@/utils/format-date";
import { installationTerminStageList } from "@/utils/constants";
import InlineLoader from "../Loaders/InlineLoader.vue";

type Props = {
  installationTermin: InstallationTermin;
  activeTerminId: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectInstallationTermin',
  props: {
    installationTermin: {},
    activeTerminId: {}
  },
  emits: ["on-close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const closeTerminModal = () => {
  emit("on-close");
};

const activeTermin = ref();

const projectTerminLoading = ref(true);

const getProjectTerminData = async (id: string) => {
  const response = await client.get(`/projects/notes/${id}`);

  activeTermin.value = response.data;

  projectTerminLoading.value = false;
};

onBeforeMount(() => {
  getProjectTerminData(props.activeTerminId);
});

const removeLoaderOverlay = (index: string) => {
  const loader = document.getElementById(`loader-${index}`);
  if (loader) {
    loader.remove();
  }
};

watch(
  () => props.activeTerminId,
  (newTerminId: string) => {
    projectTerminLoading.value = true;
    getProjectTerminData(newTerminId);
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (projectTerminLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(InlineLoader)
              ]))
            : (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(activeTermin.value?.name), 1)),
          (projectTerminLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(InlineLoader)
              ]))
            : (_openBlock(), _createElementBlock("span", {
                key: 3,
                class: "text-sm font-normal text-[#616068] rounded-md py-[2px] px-2",
                style: _normalizeStyle({
              backgroundColor: _unref(installationTerminStageList).find(
                (status) => status.name === activeTermin.value?.stage
              )?.color,
            })
              }, _toDisplayString(activeTermin.value?.stage), 5))
        ]),
        _createElementVNode("div", {
          class: "cursor-pointer",
          onClick: closeTerminModal
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Close"
          }, null, -1)
        ]))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_8, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_1,
            alt: "Calendar icon"
          }, null, -1)),
          (projectTerminLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(InlineLoader)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(formatStartEndDate)(activeTermin.value?.start, activeTermin.value?.end)), 1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_2,
            alt: "Calendar icon"
          }, null, -1)),
          (projectTerminLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(InlineLoader)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(activeTermin.value?.teams), 1))
        ])
      ])
    ]),
    (projectTerminLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
            return _createElementVNode("div", { key: index }, [
              _createVNode(TerminLoader)
            ])
          }), 64))
        ]))
      : (Object.keys(activeTermin.value?.images).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeTermin.value.images, (terminDetail, ptName, ptIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: ptIndex,
                class: "mt-5"
              }, [
                _createElementVNode("h3", _hoisted_16, _toDisplayString(ptName), 1),
                _createElementVNode("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(terminDetail, (terminImage, imgIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: imgIndex,
                      class: "flex items-center gap-4"
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", {
                          id: `loader-${ptIndex}-${imgIndex}`,
                          class: "absolute w-[350px] h-[350px] bg-[#E3E1EA]"
                        }, [
                          _createVNode(ImageLoader)
                        ], 8, _hoisted_19),
                        _createElementVNode("img", {
                          class: "max-w-[350px] w-[350px] h-[350px] object-cover rounded-lg",
                          src: terminImage,
                          alt: terminDetail.title,
                          onLoad: ($event: any) => (removeLoaderOverlay(`${ptIndex}-${imgIndex}`))
                        }, null, 40, _hoisted_20)
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "No Installation Documents"
            }, null, -1)
          ])))
  ]))
}
}

})